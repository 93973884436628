'use strict';

export function ScrollSlide() {
  const repeatCount = 2
  const slideContainer = document.querySelector('.js-scroll-slide-container');
  const slideList = document.querySelector('.js-scroll-slide-list');
  if(slideContainer !== null) {
    slideContainer.style.width = `${slideList.clientWidth * (repeatCount + 1)}px`
    for(let index = 0; index < repeatCount; index++) {
      let clonedElements = slideList.cloneNode(true);
      slideContainer.appendChild(clonedElements);
    }
  }
}