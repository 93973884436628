'use strict';

// stylesheet import
import '../sass/main.sass';
// import '../scss/main.scss';

import Vue from 'vue';
import App from './vue/app.vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { ScrollTop } from './modules/scroll-top.js';
import { ScrollSlide } from './modules/scroll-slide.js';
import { DrawerNavigation } from './modules/drawer-navigation.js';
import { CallPhone } from './modules/call-phone.js';
const $ = require('jQuery');
// const Vue = require('vue');

// Vue.js
new Vue(App).$mount('#app');

// jQuery
$(function() {
  
  // accordion panel
  $('.js-accordion-panel-toggle-button').each(function() {
    $(this).on('click', function() {
      $(this).prev('.__answer').slideToggle();
      $(this).toggleClass('__close');
    });
  });

});

// GSAP
gsap.registerPlugin(ScrollTrigger);
const fadeInItems = gsap.utils.toArray('.js-scroll-animation--fadein');
fadeInItems.forEach((item) => {
  gsap.fromTo(item,
    { 
      y: 64,
      autoAlpha: 0
    },
    {
      y: 0,
      autoAlpha: 1,
      delay: 0.2,
      duration: 1.2,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: item,
        start: 'top bottom'
      }
    }
  );
});

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();

// scroll top
ScrollTop();

// scroll slide
window.addEventListener('load', () => {
  ScrollSlide();
});

// drawer navi
DrawerNavigation();

// call phone
if(window.matchMedia('(max-width: 768px)').matches) {
  CallPhone();
}