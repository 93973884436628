<script>
  export default {
    data() {
      return {
        currentFadeSlide: 1,
        fadeSlides: 3
      }
    },
    created() {
      this.fadeSlide(4000);
    },
    methods: {
      fadeSlide(duration) {
        window.setInterval(() => {
          if(this.currentFadeSlide < this.fadeSlides) {
            this.currentFadeSlide++;
          } else {
            this.currentFadeSlide = 1;
          }
        }, duration);
      }
    }
  }
</script>

