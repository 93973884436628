'use strict';

export function DrawerNavigation() {
  document.querySelector('.js-hamburger').addEventListener('click', (event) => {
    document.querySelector('.js-drawer-navi').classList.toggle('__shown');
    event.target.classList.toggle('__close');
  });
  let currentWidth = window.innerWidth;
  window.addEventListener('resize', () => {
    if (currentWidth === window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    document.querySelector('.js-drawer-navi').classList.remove('__shown');
    document.querySelector('.js-hamburger').classList.remove('__close');
  });
}