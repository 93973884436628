'use strict';

export function CallPhone() {
  const callButton = document.querySelectorAll('.js-phone-call');
  if(callButton !== null) {
    callButton.forEach((button) => {
      const phoneNumber = button.getAttribute('data-number');
      const phoneNumberNoHyphen = phoneNumber.replace(/-/g, '');
      button.addEventListener('click', () => {
        if (window.confirm(`TEL：${phoneNumber}に発信しますか？`)) {
          location.href = `tel:${phoneNumberNoHyphen}`;
        } else {
          window.alert('発信がキャンセルされました');
        }
      });
    });
  }
}